<template>
  <div class="content">
    <div class="wrapper">
      <div class="form">
        <div class="guid-name">
          <div class="des">{{ createInfo.name }}:</div>
          <van-field
            class="name-input"
            v-model="dataInfo.name"
            type="text"
            :maxlength="nameSize[mbLang]"
            :placeholder="inputTips.name"
          />
        </div>
        <div class="guid-icon">
          <span class="des">{{ createInfo.badge }}:</span>
          <div class="upload-com">
            <VanUploader
              v-model="imageList"
              multiple
              accept="image/jpg, image/jpeg, image/png"
              image-fit="round"
              @oversize="onOversize"
              :max-size="100 * 1024"
              :max-count="1"
            >
              <div class="upload">+</div>
            </VanUploader>
          </div>
        </div>
        <div class="guid-brief">
          <div class="des">{{ createInfo.profile }}:</div>
          <van-field
            class="des-text"
            v-model="dataInfo.doc"
            rows="5"
            autosize
            type="textarea"
            :maxlength="decSize[mbLang]"
            :placeholder="inputTips.dec"
            show-word-limit
          />
        </div>
        <div class="point">
          <div>{{ createInfo.point1 }}</div>
          <div>{{ createInfo.point2 }}</div>
        </div>
      </div>
      <div class="notice">
        {{ createInfo.fee }}:
        <span style="font-weight: bold">{{ payInfo.price }}MEB</span>
      </div>
      <button class="create" @click="handleCreate">
        {{ createInfo.create }}
      </button>
      <button class="cancel" @click="$gbUtils.handleToPage('/community')">
        {{ createInfo.cancel }}
      </button>
    </div>
  </div>
</template>

<script>
import { querryPayInfo, queryCreate } from '../../services/community'
import { mapState } from 'vuex'
export default {
  data () {
    return {
      dataInfo: {
        name: '', // 社区名字
        logo: '', // logo
        doc: '', // 描述
        urlText: '', // 个性按钮链接
        url: '' // 个性按钮文字
      },
      imageList: [],
      fileList: [],
      payInfo: {
        price: 0
      },
      createMebAmount: 0, // 创建所需费用 meb
      nameSize: {
        ZH: 12,
        EN: 24
      },
      decSize: {
        ZH: 50,
        EN: 100
      }
    }
  },
  methods: {
    onOversize (file) {
      this.$toast(this.inputTips.logoSize)
    },
    // 获取限制信息
    getConfig () {
      console.log(this.mbLang)
    },
    // 获取支付信息
    getPayInfo () {
      querryPayInfo().then(res => {
        this.payInfo = res.result
      })
    },
    // 创建
    handleCreate () {
      if (!this.dataInfo.name || !this.dataInfo.doc || this.imageList.length <= 0) {
        return this.$toast(this.inputTips.inputError)
      }
      this.$dialog.confirm({
        title: this.dialogInfo.tips,
        message: this.dialogInfo.createTitle,
        confirmButtonColor: '#A85FFC',
        confirmButtonText: this.common.confirm,
        cancelButtonText: this.common.cancel
      }).then(() => {
        this.create()
      })
    },
    async create () {
      this.dataInfo.logo = this.imageList[0].content
      const resp = await queryCreate(this.dataInfo)
      if (!resp.success) return
      this.handlePay()
    },
    // 支付meb
    async handlePay () {
      this.$emit('changeLoading', true)
      this.$emit('changeLoadingIconType', 2)
      const resp = await this.$web3.transferMeb(this.payInfo.address, this.payInfo.price)
      this.$emit('changeLoading', false)
      if (resp.success) {
        this.$toast(this.inputTips.createTips)
        setTimeout(() => {
          // 跳转
          this.$gbUtils.handleToPage('/community')
        }, 2000)
      } else {
        this.$toast('fail')
      }
    }
  },
  mounted () {
    this.getPayInfo()
    this.getConfig()
  },
  watch: {
    mbLang (val) {
      this.getConfig()
    }
  },
  computed: {
    ...mapState(['mbLang']),
    common () {
      return this.$t('common')
    },
    createInfo () {
      return this.$t('community.createInfo')
    },
    inputTips () {
      return this.$t('community.tips')
    },
    dialogInfo () {
      return this.$t('community.dialog')
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  box-sizing: border-box;
  width: 100%;
  overflow-x: hidden;
  padding-bottom: 50px;
  padding: 20px 15px;
  color: #16172a;
  .wrapper {
    box-sizing: border-box;
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    clear: both;
    padding: 30px 20px;
    box-sizing: border-box;
    .des {
      width: 100%;
      padding-bottom: 15px;
    }
    .name-input {
      width: 100%;
      box-sizing: border-box;
      display: block;
      // height: 50px;
      background-color: transparent;
      border: 1px solid #c1c1c1;
      border-radius: 10px;
      margin: 0 auto;
      padding-left: 20px;
    }
    /deep/ .van-uploader__preview,
    /deep/ .van-image,
    /deep/ .van-uploader__preview-image {
      width: 60px;
      height: 60px;
      border-radius: 50%;
    }
    /deep/ .van-uploader__preview-delete {
      background-color: #fff;
    }
    /deep/.van-icon,
    /deep/.van-icon-cross,
    /deep/.van-uploader__preview-delete-icon {
      color: #16172a;
    }
    .guid-icon {
      margin: 20px 0;
      .upload-com {
        margin-top: 10px;
        border-radius: 30px;
        .upload {
          width: 60px;
          height: 60px;
          border-radius: 30px;
          border: 1px solid #c1c1c1;
          text-align: center;
          line-height: 60px;
          color: #c1c1c1;
        }
        img {
          object-fit: cover;
        }
      }
    }
    .des-text {
      border: 1px solid #c1c1c1;
      border-radius: 10px;
    }
    .notice {
      font-size: 12px;
      font-weight: 400;
      color: #818086;
      text-align: center;
      margin-top: 30px;
    }
    .point {
      font-size: 12px;
      font-weight: 400;
      color: #818086;
      // text-align: center;
      margin-top: 20px;
    }
    .create,
    .cancel {
      width: 100%;
      display: block;
      margin: 0 auto;
      height: 50px;
      line-height: 50px;
      text-align: center;
      color: #fff;
      background-color: #a85ffc;
      border: none;
      border-radius: 10px;
      font-size: 17px;
      margin-top: 15px;
    }
    .cancel {
      background-color: #c1c1c1;
    }
  }
}
</style>
